<template>
    <div>
        <div class="title">
            <h1 class="content">アカウントの権限がありませんですので、管理者にご連絡してください。</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "permission_denied"
}
</script>

<style scoped>

.title {
    height: 80vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content {
    margin-top: -112px;
    font-size: 25px;
    font-weight: bold;
}
</style>
